export const useZA = () => {
  const {
    public: { siteRegion },
  } = useRuntimeConfig()
  const { locale } = useI18n()
  const isDefaultZa = computed(() => siteRegion === 'za')
  const isZa = computed(() => {
    if (unref(isDefaultZa)) {
      return true
    }
    return unref(locale) === 'za'
  })
  return { isZa, isDefaultZa }
}
