import type { RouterConfig } from '@nuxt/schema'
export default <RouterConfig>{
  routes: (_routes) => [
    ..._routes,
    {
      name: 'kyc',
      path: '/kyc/:lang',
      redirect: ({ params }) => {
        if (params.lang === 'zh') {
          return '/zh_Hans/kyc'
        }
        return '/en/kyc'
      },
    },
  ],
}
