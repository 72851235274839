export default defineNuxtRouteMiddleware(async (to) => {
  if (import.meta.client) {
    return
  }
  const state = useServiceConifg()
  const { appUrl } = useRuntimeConfig()
  state.value = {
    ...state.value,
    appUrl: appUrl,
  }
})
