<template>
  <NuxtLayout>
    <NuxtLoadingIndicator class="tw-relative tw-z-50" />
    <NuxtPage />
    <ClientOnly>
      <VueCookieConsent />
      <RegisteModal />
    </ClientOnly>
  </NuxtLayout>
</template>
<script setup lang="ts">
  import '@/assets/scss/global.scss'
  import { LANGUAGES } from './config'
  const state = useServiceConifg()
  const { isDefaultZa } = useZA()
  const links = [
    {
      rel: 'alternate',
      langhref: 'x-default',
      href: `https://${unref(state).appUrl}`,
    },
  ]
  if (!isDefaultZa.value) {
    links.concat(
      Object.keys(LANGUAGES).map((name) => ({
        rel: 'alternate',
        langhref: (LANGUAGES as any)[name].langhref,
        href: `https://${unref(state).appUrl}/${name}`,
      })),
    )
  }
  useHead({
    link: links,
  })
</script>
