<template>
  <div class="tw-flex tw-justify-center tw-items-center tw-h-screen tw-bg-[#f7fafc]">
    <div class="tw-flex text-neutral tw-text-xl">
      <div class="tw-px-4 tw-border-r tw-border-[#ceced0]"> {{ error.statusCode }} </div>
      <div class="tw-ml-4 tw--uppercase"> {{ error.message }} </div>
    </div>
  </div>
</template>
<script setup>
  // customise 404 message from script section
  const error = useError()
  if (error.value.statusCode === 404 || '404') {
    error.value.message = 'Not Found'
  }
</script>
