<template>
  <NuxtLinkLocale
    v-if="to"
    :to="to"
    class="tw-btn disabled:!tw-text-[#d9d9d9] disabled:!tw-border-[#d9d9d9] disabled:!tw-bg-[#f5f5f5]"
    :class="{
      'ma-btn-primary-1 tw-rounded-none': props.theme === 'primary-1',
      'ma-btn-primary-2 tw-rounded-none': props.theme === 'primary-2',
      'ma-btn-secondary-1 tw-rounded-full tw-btn-md lg:tw-btn-lg ': props.theme === 'secondary-1',
      'ma-btn-secondary-2 tw-rounded-full tw-btn-md lg:tw-btn-lg ': props.theme === 'secondary-2',
      'ma-btn-secondary-3 tw-rounded-full tw-btn-md lg:tw-btn-lg ': props.theme === 'secondary-3',
      'ma-btn-secondary-4 tw-rounded-full tw-btn-md lg:tw-btn-lg ': props.theme === 'secondary-4',
    }"
    ><slot
  /></NuxtLinkLocale>
  <button
    v-else
    class="tw-btn tw-inline-block disabled:!tw-text-[#d9d9d9] disabled:!tw-border-[#d9d9d9] disabled:!tw-bg-[#f5f5f5]"
    :class="{
      'ma-btn-primary-1 tw-rounded-none': props.theme === 'primary-1',
      'ma-btn-primary-2 tw-rounded-none': props.theme === 'primary-2',
      'ma-btn-secondary-1 tw-rounded-full tw-btn-md lg:tw-btn-lg ': props.theme === 'secondary-1',
      'ma-btn-secondary-2 tw-rounded-full tw-btn-md lg:tw-btn-lg ': props.theme === 'secondary-2',
      'ma-btn-secondary-3 tw-rounded-full tw-btn-md lg:tw-btn-lg ': props.theme === 'secondary-3',
    }"
    :disabled="props.disabled || props.loading || false"
  >
    <div class="tw-flex tw-justify-center tw-items-center tw-gap-2 tw-text-inherit">
      <span class="tw-loading tw-loading-spinner tw-loading-xs md:tw-loading-md tw-mr-2" v-if="props.loading"></span>
      <slot name="prepend-inner" v-else />
      <slot />
      <slot name="append-inner" />
    </div>
  </button>
</template>
<script setup lang="ts">
  const props = withDefaults(
    defineProps<{
      theme?: 'primary-1' | 'primary-2' | 'secondary-1' | 'secondary-2' | 'secondary-3'|'secondary-4' | 'define'
      disabled?: boolean
      loading?: boolean
      to?: string
    }>(),
    {
      theme: 'primary-1',
    },
  )
</script>
<style lang="scss" scoped>
  /* Start buttons global rules*/
  .ma-btn-primary-2,
  .ma-btn-primary-1 {
    position: relative;
    overflow: hidden;
    text-decoration: none;
    text-align: center;
    outline: none;
    border: 2px solid transparent;
    // box-shadow: 0px 7px 10px -5px rgba(0, 0, 0, 0.25);
    transition: all 0.5s ease-in-out 0s;
    cursor: pointer;
    z-index: 0;
    min-width: 200px;
  }

  .disabled {
    cursor: pointer;
    border: 2px solid;
    border-color: #d9d9d9;
    color: rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
    text-decoration: none;
    text-align: center;
  }

  .ma-btn-primary-2:hover,
  .ma-btn-primary-1:hover {
    text-decoration: none;
  }

  .ma-btn-primary-2:focus,
  .ma-btn-primary-1:focus {
    outline: none !important;
  }

  .ma-btn-primary-1 {
    border: 2px solid #c5ab80;
    color: #c5ab80;
    background: transparent;
  }

  .ma-btn-primary-1:hover {
    background: #c5ab80;
    color: #fff;
  }

  .ma-btn-primary-1:hover::before,
  .ma-btn-primary-1:hover::after {
    left: 100%;
    transform: rotate(-15deg) translateX(100%);
  }

  .ma-btn-primary-1::before,
  .ma-btn-primary-1::after {
    content: '';
    position: absolute;
    width: 30px;
    left: -1%;
    top: -50%;
    height: 200%;
    background-color: rgba(255, 255, 255, 0.7);
    transform: rotate(-15deg) translateX(-150%);
    z-index: -1;
    transition: all 0.5s ease-in-out 0s;
  }

  .ma-btn-primary-1::after {
    transition-delay: 0.075s;
  }

  .ma-btn-primary-2 {
    border: 2px solid currentColor;
    border-image-source: linear-gradient(256deg, #f2e8d2 50%, #c5ab80);
    border-image-slice: 1;
    color: #c5ab80;
    background: transparent;
  }

  .ma-btn-primary-2:hover {
    background: #c5ab80;
    color: #fff;
  }

  .ma-btn-primary-2:hover::before,
  .ma-btn-primary-2:hover::after {
    left: 100%;
    transform: rotate(-15deg) translateX(100%);
  }

  .ma-btn-primary-2::before,
  .ma-btn-primary-2::after {
    content: '';
    position: absolute;
    width: 30px;
    left: -2%;
    top: -50%;
    height: 200%;
    background-color: rgba(255, 255, 255, 0.7);
    transform: rotate(-15deg) translateX(-105%);
    z-index: -1;
    transition: all 0.5s ease-in-out 0s;
  }

  .ma-btn-primary-2::after {
    transition-delay: 0.065s;
  }

  /* Start buttons global rules*/
  .ma-btn-secondary-2,
  .ma-btn-secondary-1,
  .ma-btn-secondary-3,
  .ma-btn-secondary-4 {
    display: inline-flex;
    position: relative;
    overflow: hidden;
    text-decoration: none;
    text-align: center;
    outline: none;
    border: 2px solid transparent;
    transition: all 0.5s ease-in-out 0s;
    cursor: pointer;
    z-index: 0;
    min-width: 200px;
    justify-content: center;
    align-items: center;
  }

  .ma-btn-secondary-2:hover,
  .ma-btn-secondary-1:hover,
  .ma-btn-secondary-3:hover,
  .ma-btn-secondary-4:hover {
    text-decoration: none;
  }

  .ma-btn-secondary-2:focus,
  .ma-btn-secondary-1:focus,
  .ma-btn-secondary-3:focus,
  .ma-btn-secondary-4:focus {
    outline: none !important;
  }

  .ma-btn-secondary-1 {
    border-color: #0a0a16;
    color: #fff;
    background: #0a0a16;
  }

  .ma-btn-secondary-1:hover {
    color: #0a0a16;
    background: transparent;
    border-color: #0a0a16;
  }

  .ma-btn-secondary-1:hover::before,
  .ma-btn-secondary-1:hover::after {
    left: 100%;
    transform: rotate(-15deg) translateX(100%);
  }

  .ma-btn-secondary-1::before,
  .ma-btn-secondary-1::after {
    content: '';
    position: absolute;
    width: 30px;
    left: -1%;
    top: -50%;
    height: 200%;
    background-color: rgba(255, 255, 255, 0.7);
    transform: rotate(-15deg) translateX(-150%);
    z-index: -1;
    transition: all 0.5s ease-in-out 0s;
  }

  .ma-btn-secondary-1::after {
    transition-delay: 0.075s;
  }

  .ma-btn-secondary-2 {
    /**/
    border: 2px solid;
    border-color: #fff;
    color: #fff;
    background: hsla(0, 0%, 100%, 0.3);
  }

  .ma-btn-secondary-2:hover {
    background: #0a0a16;
    color: #fff;
  }

  .ma-btn-secondary-2:hover::before,
  .ma-btn-secondary-2:hover::after {
    left: 100%;
    transform: rotate(-15deg) translateX(100%);
  }

  .ma-btn-secondary-2::before,
  .ma-btn-secondary-2::after {
    content: '';
    position: absolute;
    width: 30px;
    left: -2%;
    top: -50%;
    height: 200%;
    background-color: rgba(255, 255, 255, 0.7);
    transform: rotate(-15deg) translateX(-105%);
    z-index: -1;
    transition: all 0.5s ease-in-out 0s;
  }

  .ma-btn-secondary-2::after {
    transition-delay: 0.065s;
  }

  //********* */

  .ma-btn-secondary-3 {
    /**/
    border: 2px solid;
    border-color: #fff;
    color: #0a0a16;
    background: #fff;
  }

  .ma-btn-secondary-3:hover {
    background: #0a0a16;
    color: #fff;
  }

  .ma-btn-secondary-3:hover::before,
  .ma-btn-secondary-3:hover::after {
    left: 100%;
    transform: rotate(-15deg) translateX(100%);
  }

  .ma-btn-secondary-3::before,
  .ma-btn-secondary-3::after {
    content: '';
    position: absolute;
    width: 30px;
    left: -2%;
    top: -50%;
    height: 200%;
    background-color: rgba(255, 255, 255, 0.7);
    transform: rotate(-15deg) translateX(-105%);
    z-index: -1;
    transition: all 0.5s ease-in-out 0s;
  }

  .ma-btn-secondary-3::after {
    transition-delay: 0.065s;
  }


  .ma-btn-secondary-4 {
    /**/
    border: 2px solid;
    border-color: #c5ab80;
    color: #fff;
    background: #c5ab80;
  }

  .ma-btn-secondary-4:hover {
    background: #0a0a16;
    color: #c5ab80;
    border-color: #c5ab80;
  }

  .ma-btn-secondary-4:hover::before,
  .ma-btn-secondary-4:hover::after {
    left: 100%;
    transform: rotate(-15deg) translateX(100%);
  }

  .ma-btn-secondary-4::before,
  .ma-btn-secondary-4::after {
    content: '';
    position: absolute;
    width: 30px;
    left: -2%;
    top: -50%;
    height: 200%;
    background-color: rgba(255, 255, 255, 0.7);
    transform: rotate(-15deg) translateX(-105%);
    z-index: -1;
    transition: all 0.5s ease-in-out 0s;
  }

  .ma-btn-secondary-4::after {
    transition-delay: 0.065s;
  }
</style>
