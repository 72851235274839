export const LANGUAGES: ILanguages = {
  en: {
    name: 'English (US)',
    key: 'en',
    veriffLanguageCode: 'en', // Veriff language code
    recaptchaLanguageCode: 'en',
    flag: 'us',
    visible: true,
    langhref:'en'
  },
  de: {
    name: 'Deutsch',
    key: 'de',
    veriffLanguageCode: 'de',
    recaptchaLanguageCode: 'de',
    flag: 'de',
    visible: true,
    langhref:'de-DE'
  },
  // fr: {
  //   name: 'Français',
  //   key: 'fr',
  //   veriffLanguageCode: 'fr',
  //   recaptchaLanguageCode: 'fr',
  //   flag: 'fr',
  //   visible: true,
  //   langhref:'fr-FR'
  // },
  es_MX: {
    name: 'Español (MX)', // 墨西哥西班牙文
    key: 'es_MX',
    veriffLanguageCode: 'es-mx',
    recaptchaLanguageCode: 'es',
    flag: 'mx',
    visible: true,
    langhref:'es-MX'
  },
  pt_BR: {
    name: 'Português (BR)', //巴西葡萄牙语
    key: 'pt_BR',
    veriffLanguageCode: 'pt',
    recaptchaLanguageCode: 'pt-BR',
    flag: 'br',
    visible: true,
    langhref:'pt-BR'
  },
  zh_Hans: {
    name: '简体中文',
    key: 'zh_Hans',
    veriffLanguageCode: 'zh',
    recaptchaLanguageCode: 'zh-CN',
    flag: 'cn',
    visible: true,
    langhref:'zh-Hans'
  },
  zh_Hant: {
    name: '繁體中文',
    key: 'zh_Hant',
    veriffLanguageCode: 'zh-hant',
    recaptchaLanguageCode: 'zh-HK',
    flag: 'hk',
    visible: true,
    langhref:'zh-Hant'
  },
  za: {
    name: 'South Africa', // 南非
    key: 'za',
    veriffLanguageCode: 'en',
    recaptchaLanguageCode: 'en',
    flag: 'za',
    visible: false,
    langhref:'en-ZA'
  },
}
