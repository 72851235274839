import type { Status, Consent } from 'cookieconsent'
import 'cookieconsent/build/cookieconsent.min.css'
declare global {
  interface Window {
    cookieconsent: Consent
  }
}
export default defineNuxtPlugin((NuxtApp) => {
  NuxtApp.vueApp.use({
    install(Vue: any) {
      Vue.component('VueCookieConsent', {
        render: () => null,
        mounted() {
          const href = '/policy'
          const policy = `<a href="${href}" target="_blank" class="text-highlight">${this.$t('Privacy Policy and Cookie Policy')}</a>`

          import('cookieconsent').then(() => {
            window.cookieconsent.initialise({
              window: `<div role="dialog" aria-live="polite" aria-label="cookieconsent" aria-describedby="cookieconsent:desc" class="cc-window {{classes}}"><!--googleoff: all-->{{children}}<!--googleon: all--></div>`,
              palette: {
                popup: { background: 'rgba(30,32,63,.9)' },
                button: {
                  background: '#c5ab80',
                  border: 'transparent',
                  text: '#fff',
                },
                highlight: {
                  background: 'transparent',
                  border: 'rgba(255,255,255,.5)',
                  text: 'rgba(255,255,255,.5)',
                },
              },
              theme: 'classic',
              layouts: {
                legend: '{{header}}{{message}}{{compliance}}',
              },
              layout: 'legend' as any,
              position: 'bottom-right',
              compliance: {
                info: `<div class="cc-compliance cc-highlight tw-flex">{{deny}}{{allow}}</div>`,
                'opt-in': '<div class="cc-compliance cc-highlight">{{deny}}{{allow}}</div>',
                'opt-out': '<div class="cc-compliance cc-highlight">{{deny}}{{allow}}</div>',
              },
              type: 'info',
              content: {
                header: this.$t('WE_VALUE_YOUR_PRIVACY'),
                message: this.$t('privacy_content', { policy }),
                allow: this.$t('Accept'),
                deny: this.$t('Reject'),
                href,
              },
              elements: {
                header: `<span class="cc-header ">{{header}}</span>`,
                allow: `<a aria-label="allow cookies" role=button tabindex="0" class="cc-btn cc-allow ">{{allow}}</a>`,
                deny: `<a aria-label="deny cookies" role=button tabindex="0" class="cc-btn cc-deny">{{deny}}</a>`,
              },
              revokable: false,
              onStatusChange: function (status: Status) {
                // const popup = this as any;
                // console.log("status: %o", status);
                // console.log(
                //   popup.hasConsented() ? "enable cookies" : "disable cookies"
                // );
              },
            })
          })
        },
      })
    },
  })
})
